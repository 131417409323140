import WordsService from './WordsService';
import config from './config';

const MIN_FONT_SIZE = 16.0;
const MAX_FONT_SIZE = 48.0;
const MAX_CLICKS = 20.0;
const REFRESH_INTERVAL = 10000;
const MAX_CLICKS_PER_USER = 3;

let wordsList;
let clickedWordIds = [];

const wordsService = new WordsService(
    '/graphql',
    config.token
);

document.addEventListener('DOMContentLoaded', () => {
    initWords();
});

async function initWords() {
    wordsList = document.querySelector('#words');
    if (!wordsList) return;


    const words = await wordsService.fetchAll();
    let wordsOrdered = words;
    const orderByClicks = wordsList.dataset.order == 'clicks';
    if (orderByClicks) {
        wordsOrdered = words.sort((a, b) => {
            return a.clicks > b.clicks ? -1 : (a.clicks < b.clicks ? 1 : 0);
        });
    }

    renderWords(wordsList, wordsOrdered);
    loadClickedWordIds();
    updateClickableStatus();
    updateClickedStatus();

    setInterval(fetchAndUpdateWords, REFRESH_INTERVAL);
}

async function fetchAndUpdateWords() {
    let words = await wordsService.fetchAll();
    updateWords(wordsList, words);
    return words;
}

function renderWords(wordsList, words) {
    for (let word of words) {
         const listItem = renderlistItem(word);
         wordsList.appendChild(listItem);
    }
}

function updateWords(wordsList, words) {
    const links = wordsList.querySelectorAll('li a');
    for (let link of links) {
        const wordId = link.dataset.wordId;
        for (let word of words) {
            if (word.id == wordId) {
                link.dataset.clicks = word.clicks || 0;
                link.innerText = renderLinkText(word.title, link.dataset.clicks);
                styleWordLink(link);
            }
        }
    }
}

function renderlistItem(word) {
    const link = document.createElement('a');
    link.href = '#';
    link.dataset.wordId = word.id;
    link.dataset.clicks = word.clicks || 0;
    link.innerText = renderLinkText(word.title, link.dataset.clicks);
    link.classList.add('word');
    // link.classList.add('word--selected');
    styleWordLink(link);
    addEventListenerToWordLink(link);

    const li = document.createElement('li');
    li.appendChild(link);

    return li;
}

function renderLinkText(title, clicks) {
    // return `${title}(${clicks})`;
    return `${title}`;
}

function addEventListenerToWordLink(link) {
    link.addEventListener('click', onWordLinkClick);
}

function styleWordLink(link) {
    const clicks = parseInt(link.dataset.clicks || 0);

    let fontSize = Math.round(MIN_FONT_SIZE + clicks / MAX_CLICKS * (MAX_FONT_SIZE - MIN_FONT_SIZE));
    if (fontSize > MAX_FONT_SIZE) {
        fontSize = MAX_FONT_SIZE;
    }

    link.style.fontSize = `${fontSize}px`;
}

async function onWordLinkClick(event) {
    event.preventDefault();

    await fetchAndUpdateWords();

    const areWordsClickable = wordsList.classList.contains('words--clickable');
    if (!areWordsClickable) return;

    const link = event.target;
    const isSelected = link.classList.contains('word--selected');
    if (isSelected) return;

    const wordId = link.dataset.wordId;

    if (!clickedWordIds.includes(wordId)) {
        clickedWordIds.push(wordId);
        saveClickedWordIds();
        updateClickableStatus();
        updateClickedStatus();
    }

    const clicks = parseInt(link.dataset.clicks);
    await wordsService.updateClicks(wordId, clicks + 1);

    await fetchAndUpdateWords();

}

function loadClickedWordIds() {
    clickedWordIds =  JSON.parse(localStorage.getItem('clickedWordIds')) || [ ];
}

function saveClickedWordIds() {
    localStorage.setItem('clickedWordIds', JSON.stringify(clickedWordIds));
}

function updateClickedStatus() {
    const links = wordsList.querySelectorAll('li a');
    for (let link of links) {
        const wordId = link.dataset.wordId;
        if (clickedWordIds.includes(wordId)) {
            if (!link.classList.contains('word--selected')) {
                link.classList.add('word--selected');
            }
        }
    }
}

function updateClickableStatus() {
    if (clickedWordIds.length >= MAX_CLICKS_PER_USER) {
        if (wordsList.classList.contains('words--clickable')) {
            wordsList.classList.remove('words--clickable');
        }
    }
}
