class WordsService {
    constructor(url, token) {
        this.url = url;
        this.token = token;
    }
    
    async fetchAll() {
        const query = `
            query fetchWords {
                entries(section:[words], order: "title") {
                    ...on Words {
                        id
                        title
                        clicks
                    }
                }
            }
        `;
        
        const response = await this.doGraphqlRequest(query);
        if (response.ok) {
            const responseJson = await response.json();
            return responseJson.data.entries;
        } else {
            return [];
        }
    }
    
    async updateClicks(wordId, clicks) {
        const mutation = `
            mutation updateClicks($id:Int, $clicks:Int) {
                upsertWords(id:$id, clicks:$clicks) {
                    id
                    clicks
                }
            }
        `;
        
        const variables = {
            id: parseInt(wordId),
            clicks: parseInt(clicks)
        }
        
        const response = await this.doGraphqlRequest(mutation, variables);
         if (response.ok) {
            const responseJson = await response.json();
            return responseJson.data.upsetWords;
        } else {
            return null;
        }
    }
    
    // private -------------------------------------------------------------------
    
    headers() {
        const headers = {
            'authorization': `Bearer ${this.token}`,
            'accept': 'application/json',
            'content-type': 'application/json'
        };
        
        return headers;
    }
    
    async doGraphqlRequest(query, variables = null) {
        const url = this.url;
        const method = 'post';
        
        const data = { query };
        if (variables) {
            data.variables = variables;    
        }
        
        const body = JSON.stringify(data);
        const headers = this.headers();
        
        return fetch(url, { method, headers, body });
    }
}

export default WordsService;